import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './quasar';
import i18n from './i18n';
import './vee-validate';
import VueMoment from 'vue-moment';
import VueTour from 'vue-tour';
import 'vue-tour/dist/vue-tour.css';

Vue.use(VueTour);
Vue.use(VueMoment);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');

// import VueLodash from 'vue-lodash';
// import debounce from 'lodash/debounce';
// import throttle from 'lodash/throttle';

// Vue.use(VueLodash, { name: 'custom', lodash: { debounce, throttle } });
