/* eslint-disable no-console */

import { register } from 'register-service-worker';

const applicationServerPublicKey =
  'BLqqJvYc7cU6mH4GKbdCNXJ9vEgD7S22yU7pHjVte_snk5sGoX81gQ0VwwV42DD6NMBdaQdwChwBxeIHxhHj-Xs';

function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function postSubscribeObj(statusType: string, subscription: PushSubscription) {
  // Send the information to the server with fetch API.
  // the type of the request, the name of the user subscribing,
  // and the push subscription endpoint + key the server needs
  // to send push messages

  const userAgent = navigator.userAgent.match(
    /(firefox|msie|chrome|safari|trident)/gi
  );
  let browser = '';
  if (userAgent) {
    browser = userAgent[0].toLowerCase();
  }
  const data = {
    // eslint-disable-next-line
    status_type: statusType,
    subscription: subscription.toJSON(),
    browser: browser
  };

  const subscribe = new CustomEvent('subscribe', {
    bubbles: true,
    detail: {
      data: data
    }
  });
  document.getRootNode().dispatchEvent(subscribe);
}

function subscribeUser(registration: ServiceWorkerRegistration) {
  const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
  registration.pushManager
    .subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey
    })
    .then(function(subscription) {
      postSubscribeObj('subscribe', subscription);
    })
    .catch(function(error) {
      console.error('Failed to subscribe the user: ', error);
    });
}
function unsubscribeUser(registration: ServiceWorkerRegistration) {
  registration.pushManager
    .getSubscription()
    .then(function(subscription) {
      if (subscription) {
        postSubscribeObj('unsubscribe', subscription);
        return subscription.unsubscribe();
      }
    })
    .catch(function(error) {
      console.log('Error unsubscribing', error);
    });
}

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready(registration: ServiceWorkerRegistration) {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      );
      self.addEventListener('login', function() {
        subscribeUser(registration);
      });
      self.addEventListener('logout', function() {
        unsubscribeUser(registration);
      });
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      console.log('New content is available; please refresh.');
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });
}
