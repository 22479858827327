import Vue from 'vue';

import './styles/quasar.sass';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import {
  Quasar,
  Meta,
  Notify,
  Loading,
  LoadingBar,
  LocalStorage,
  Cookies,
  Dialog,
  AppFullscreen
} from 'quasar';

Vue.use(Quasar, {
  config: {},
  components: {
    /* not needed if importStrategy is not 'manual' */
  },
  directives: {
    /* not needed if importStrategy is not 'manual' */
  },
  plugins: {
    Meta,
    Notify,
    Loading,
    LoadingBar,
    LocalStorage,
    Cookies,
    Dialog,
    AppFullscreen
  }
});

Notify.setDefaults({
  position: 'top-right',
  progress: true,
  timeout: 2500,
  actions: [
    {
      icon: 'close',
      color: 'white',
      attrs: { round: true, 'aria-label': 'Close' }
    }
  ]
});

LoadingBar.setDefaults({
  color: 'positive',
  size: '5px',
  position: 'top'
});
